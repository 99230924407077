import { Controller } from 'stimulus';

const UNPROCESSABLE_ENTITY = 422;

export default class ApplicationFormController extends Controller {
  static targets = ['submit'];

  submit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    this._hideErrors();
    this.submitTarget.disabled = true;

    this._createApplication(data)
      .then((data) => this._onSuccess(data))
      .catch((error) => this._onError(error));
  }

  _createApplication(body) {
    const endpoint = window.location.href;

    return fetch(endpoint, { method: 'POST', body }).then((res) => {
      return res
        .json()
        .then((data) => {
          res.data = data;
        })
        .catch(Object)
        .then(() => (res.ok ? res : Promise.reject(res)));
    });
  }

  _onSuccess(response) {
    window.location.href = response.data.redirect_url;
  }

  _onError(response) {
    this.submitTarget.disabled = false;

    if (response.status === UNPROCESSABLE_ENTITY) {
      this._handleValidationErrors(response.data);
      return;
    }

    this._showFlashError();
  }

  _hideErrors() {
    $('[data-error-field]').addClass('hidden').html('');
    $('[name]').removeClass('border-red-550').addClass('border-gray-150');
    $('#flash-container').addClass('hidden');
  }

  _handleValidationErrors(errors) {
    let firstErrorElement = null;
  
    $.each(errors, function (field, messages) {
      const errorFieldElement = $(`[data-error-field="${field}"]`);
      if (errorFieldElement.length) {
        errorFieldElement.removeClass('hidden').html(messages[0]);
      }
  
      const inputElement = $(`[name="${field}"]`);
      if (inputElement.length) {
        inputElement.addClass('border-red-550').removeClass('border-gray-150');
  
        if (!firstErrorElement) {
          firstErrorElement = inputElement;
        }
      }
    });

    if (firstErrorElement) {
      firstErrorElement.focus();
    }
  }

  _showFlashError() {
    $('#flash-container').removeClass('hidden');
  }
}
